<template>
  <div class="container">
    <div class="box-title" :style="{marginTop: '-15px'}">
      <h4>
        <i class="fal fa-gifts"></i>
        ລາຍການທັງໝົດ
        <span class="countTotal">({{ count }})</span>
        <span class="btn-add" :style="{marginTop: '-5px !important'}"
              v-if="($route.query.tab === 'origin_freight_fees'
              || $route.query.tab === 'destination_freight_fees')
              && $can(['SuperAdmin','manager','service','marketing','suburbMarketing'])">
                    ຍອດຄ່າຂົນສົ່ງທັງໝົດ: {{
            FormatPrice(totalFreight)
          }} | ຈຳນວນພັດສະດຸທັງໝົດ: {{ FormatPrice(totalPieces).replace(" ກີບ", "") }} ກ່ອງ
          </span>
        <span class="btn-add" :style="{marginTop: '-5px !important'}"
              v-else-if="($route.query.tab === 'origin_freight_fees_cod'
              || $route.query.tab === 'destination_freight_fees_cod'
              || $route.query.tab === 'all')
              && $can(['SuperAdmin','manager', 'marketing'])">
                     ຍອດຄ່າຂົນສົ່ງທັງໝົດ: {{ FormatPrice(totalFreight) }} |  ຍອດຄ່າສິນຄ້າ: {{ FormatPrice(totalPrice) }}
                      | ຈຳນວນພັດສະດຸທັງໝົດ: {{ FormatPrice(totalPieces).replace(" ກີບ", "") }} ກ່ອງ
          </span>
        <span class="btn-add">
          <div class="columns">
            <div v-if="$can(['SuperAdmin','service','manager','marketing','suburbMarketing'])" class="field"
                 :style="{marginRight: '5px'}">
             <vs-button
                 ref="button" flat
                 @click="printShipment()"
                 class="form-btn-loading"
                 :loading="loading"
                 :disabled="listAllOrders.length <= 0">
               export excel
             </vs-button>
            </div>
            <div class="field" style="margin-right: 12px">
              <vs-button
                  ref="button"
                  @click="ClearData()"
                  class="form-cls-loading"
                  :loading="loading">
                ລ້າງຂໍ້ມູນ
              </vs-button>
            </div>
          </div>
        </span>
      </h4>
    </div>

    <div class="box" style="margin-bottom: 12px;margin-top: -12px;">
      <div class="columns">
        <div class="column is-2">
          <div class="field">
            <div class="control">
              <label class="label">ຄົ້ນຫາ:</label>
              <input
                  class="input"
                  placeholder="ຄົ້ນຫາ..."
                  type="text" name="name"
                  v-model="searchItem"
                  @keyup.enter="FilterByShipmentNumber()"
              >
            </div>
          </div>
        </div>

        <div class="column is-2">
          <div class="control">
            <label class="label">ປະເພດຂົນສົ່ງ:</label>
            <div class="select">
              <select class="input" v-model="selectedDeliverType" @change="FetchData(groupBy = true)">
                <option value="">ທັງໝົດ</option>
                <option value="express">express</option>
                <option value="logistics">logistics</option>
              </select>
            </div>
          </div>
        </div>

        <div class="column is-2">
          <div class="control">
            <label class="label">ປະເພດສາຂາຕົ້ນທາງ:</label>
            <div class="select">
              <select class="input" v-model="selectedStartBranchType" @change="FetchListStartBranch">
                <option value="">ທັງໝົດ</option>
                <option value="branch">ສາຂາຫຼັກທັງໝົດ</option>
                <option value="branch_capital">ສາຂາຫຼັກນະຄອນຫຼວງ</option>
                <option value="branch_province">ສາຂາຫຼັກຕ່າງແຂວງ</option>
                <option value="franchise">ສາຂາແຟນຊາຍ</option>
                <option value="delivery">ສາຂາເດລີເວີລີ</option>
                <option value="local_branch">ຈຸດສູນແຈກຢາຍ</option>
              </select>
            </div>
          </div>
        </div>

        <div class="column is-2">
          <div class="control">
            <label class="label">ປະເພດສາຂາປາຍທາງ:</label>
            <div class="select">
              <select class="input" v-model="selectedEndBranchType" @change="FetchListEndBranch">
                <option value="">ທັງໝົດ</option>
                <option value="branch">ສາຂາຫຼັກທັງໝົດ</option>
                <option value="branch_capital">ສາຂາຫຼັກນະຄອນຫຼວງ</option>
                <option value="branch_province">ສາຂາຫຼັກຕ່າງແຂວງ</option>
                <option value="franchise">ສາຂາແຟນຊາຍ</option>
                <option value="delivery">ສາຂາເດລີເວີລີ</option>
                <option value="local_branch">ຈຸດສູນແຈກຢາຍ</option>
              </select>
            </div>
          </div>
        </div>

        <div class="column is-2">
          <div class="control">
            <label class="label">ສາຂາຕົ້ນທາງ:</label>
            <model-list-select
                :list="startBranches"
                v-model="selectedStartBranch"
                placeholder="ເລືອກສາຂາຕົ້ນທາງ"
                option-text="name"
                option-value="id"
                selectedItem="name"
            >
            </model-list-select>
          </div>
        </div>
        <div class="column is-2">
          <div class="control">
            <label class="label">ສາຂາປາຍທາງ:</label>
            <model-list-select
                :list="endBranches"
                v-model="selectedEndBranch"
                placeholder="ເລືອກສາຂາປາຍທາງ"
                option-text="name"
                option-value="id"
                selectedItem="name"
            >
            </model-list-select>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-2">
          <div class="control field">
            <label class="label">ຈາກວັນທີ:</label>
            <date-picker
                format="DD/MM/YYYY HH:mm"
                value-type="YYYY-MM-DD HH:mm"
                type="datetime"
                v-model="startDate"
                :disabled-date="disabledAfterToday"
                v-validate="'required'"
                :clearable="false"
                name="valid_date"
            ></date-picker>
          </div>
        </div>
        <div class="column is-2">
          <div class="control">
            <label class="label">ຫາວັນທີ:</label>
            <date-picker
                format="DD/MM/YYYY HH:mm"
                value-type="YYYY-MM-DD HH:mm"
                type="datetime"
                v-model="endDate"
                :disabled-date="disabledAfterToday"
                v-validate="'required'"
                :clearable="false"
                name="valid_date"
            ></date-picker>
          </div>
        </div>
        <div class="column is-2">
          <div class="control">
            <label class="label">ສະຖານະ:</label>
            <div class="select">
              <select class="input" v-model="selectedStatus" @change="FetchData(groupBy = false)">
                <option value="">ທັງໝົດ</option>
                <option v-for="(item, index) in shipmentStatus" :key="index" :value="item.id">
                  <span v-if="item.name === 'Pending'">ລໍຖ້າດໍາເນີນການ</span>
                  <span v-if="item.name === 'Processing'">ກຳລັງດຳເນີນການ</span>
                  <span v-if="item.name === 'Arrived'">ເຄື່ອງຮອດສາຂາປາຍທາງ</span>
                  <span v-if="item.name === 'Delivering'">ກໍາລັງຈັດສົ່ງ</span>
                  <span v-if="item.name === 'Success'">ຈັດສົ່ງສຳເລັດ</span>
                  <span v-if="item.name === 'Single Sign'">ຮັບເຄື່ອງແບບແຍກ</span>
                  <span v-if="item.name === 'Timeout'">ຈັດສົ່ງເຄື່ອງມື້ອື່ນ</span>
                  <span v-if="item.name === 'Contact Error'">ເບີຕິດຕໍ່ບໍ່ໄດ້,ບໍ່ສາມາດຈັດສົ່ງ</span>
                  <span v-if="item.name === 'Lost'">ເຄື່ອງເສຍ</span>
                  <span v-if="item.name === 'Return To Sender'">ຈັດສົ່ງຄືນຜູ້ຝາກ</span>
                  <span v-if="item.name === 'Address Error'">ທີ່ຢູ່ຜິດ(ປ່ຽນທີຢູ່ໃຫມ່)</span>
                  <span v-if="item.name === 'Trouble'">ສະຖານະບໍ່ປົກກະຕິ</span>
                  <span v-if="item.name === 'Disposed'">ທໍາລາຍ</span>
                  <span v-if="item.name === 'Customer Request Cancel'">ລູກຄ້າຍົກເລີກ</span>
                  <span v-if="item.name === 'Wrong Flow'">ເຄື່ອງທີ່ຍິງຂ້າມ</span>
                  <span v-if="item.name === 'Expires'">ໝົດເຂດຮັບເຄື່ອງ</span>
                  <span v-if="item.name === 'BillWasSettled'">ຮຽກເກັບເງິນສຳເລັດແລ້ວ</span>
                  <span v-if="item.name === 'SuccessfullyCompensated'">ຊົດເຊີຍເງິນສຳເລັດ</span>
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="column is-2">
          <div class="field">
            <div class="control">
              <vs-button
                  ref="button" flat
                  @click="FetchData()"
                  class="form-btn-loading"
                  :loading="loading">
                ຄົ້ນຫາ
              </vs-button>
            </div>
          </div>
        </div>
        <!--        <div v-if="$can('SuperAdmin')" class="column is-2">-->
        <!--          <div class="control">-->
        <!--            <label class="label">ປະເພດຜູ້ສົ່ງ:</label>-->
        <!--            <div class="select">-->
        <!--              <select class="input" v-model="selectedSenderType" @change="FetchData(groupBy = true)">-->
        <!--                <option value="">ທັງໝົດ</option>-->
        <!--                <option value="general">ລູກຄ້າທົ່ວໄປ</option>-->
        <!--                <option value="company">ລູກຄ້າລາຍເດືອນ</option>-->
        <!--              </select>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </div>-->
        <!--        <div v-if="$can('SuperAdmin')" class="column is-2">-->
        <!--          <div class="control">-->
        <!--            <label class="label">ປະເພດຜູ້ຮັບ:</label>-->
        <!--            <div class="select">-->
        <!--              <select class="input" v-model="selectedReceiverType" @change="FetchData(groupBy = true)">-->
        <!--                <option value="">ທັງໝົດ</option>-->
        <!--                <option value="general">ລູກຄ້າທົ່ວໄປ</option>-->
        <!--                <option value="company">ລູກຄ້າລາຍເດືອນ</option>-->
        <!--              </select>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </div>-->
        <!--        <div v-if="$can('SuperAdmin')" class="column is-2">-->
        <!--          <div class="control">-->
        <!--            <label class="label">ຜູ້ສ້າງບິນ:</label>-->
        <!--            <model-list-select-->
        <!--                :list="users"-->
        <!--                v-model="selectedUser"-->
        <!--                placeholder="ຜູ້ສ້າງບິນ"-->
        <!--                option-text="name"-->
        <!--                option-value="id"-->
        <!--                selectedItem="name">-->
        <!--            </model-list-select>-->
        <!--          </div>-->
        <!--        </div>-->
      </div>
      <!--      <div v-if="$can('SuperAdmin')">-->
      <!--        <div class="columns">-->
      <!--          <div class="column is-2">-->
      <!--            <div class="control">-->
      <!--              <label class="label">ຜູ້ຈັດສົ່ງ:</label>-->
      <!--              <model-list-select-->
      <!--                  :list="signUsers"-->
      <!--                  v-model="selectedSignUser"-->
      <!--                  placeholder="ຜູ້ຈັດສົ່ງ"-->
      <!--                  option-text="name"-->
      <!--                  option-value="id"-->
      <!--                  selectedItem="name">-->
      <!--              </model-list-select>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--          <div class="column is-2">-->
      <!--            <div class="control">-->
      <!--              <label class="label">ປະເພດຜູ້ສ້າງບິນ:</label>-->
      <!--              <div class="select">-->
      <!--                <select class="input" v-model="selectedUserType" @change="FetchData(groupBy = true)">-->
      <!--                  <option value="">ທັງໝົດ</option>-->
      <!--                  <option value="branch">ສາຂາ</option>-->
      <!--                  <option value="delivery">ເດລີເວີລີ</option>-->
      <!--                </select>-->
      <!--              </div>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--          <div class="column is-2">-->
      <!--            <div class="control">-->
      <!--              <label class="label">ປະເພດຜູ້ຈັດສົ່ງ:</label>-->
      <!--              <div class="select">-->
      <!--                <select class="input" v-model="selectedSignUserType" @change="FetchData(groupBy = true)">-->
      <!--                  <option value="">ທັງໝົດ</option>-->
      <!--                  <option value="branch">ສາຂາ</option>-->
      <!--                  <option value="delivery">ເດລີເວີລີ</option>-->
      <!--                </select>-->
      <!--              </div>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->
    </div>

    <div class="center table-limit-height">
      <!--      <vs-table v-model="pageInvoices" ref="table">-->
      <vs-table ref="table">
        <template #thead>
          <vs-tr>
            <vs-th></vs-th>
            <!--            <vs-th>-->
            <!--              <div class="center">-->
            <!--                <vs-checkbox danger-->
            <!--                             @click="onCheckAllCheckBoxChanged"-->
            <!--                             :value="isIndeterminate || isSelectAllOrders"-->
            <!--                             :loading="isSelecting"-->
            <!--                             :checked-force="isSelectAllOrders"-->
            <!--                             :indeterminate="isIndeterminate">-->
            <!--                  <span :style="{fontSize: '16px', marginLeft: '8px'}">ທັງໝົດ</span>-->
            <!--                </vs-checkbox>-->
            <!--              </div>-->
            <!--            </vs-th>-->
            <vs-th>ເລກທີບິນ</vs-th>
            <vs-th>ວັນທີຝາກເຄື່ອງ</vs-th>
            <vs-th>ວັນທີແກ້ໄຂບິນ</vs-th>
            <vs-th>ວັນທີເຄື່ອງຮອດ</vs-th>
            <vs-th>ວັນທີຈັດສົ່ງສຳເລັດ</vs-th>
            <vs-th>ສະຖານະ</vs-th>
            <vs-th>ລາຍລະອຽດບິນ</vs-th>
            <vs-th>ຈຳນວນ</vs-th>
            <vs-th>ນ້ຳໜັກ</vs-th>
            <vs-th>ສ່ວນຫຼຸດ</vs-th>
            <vs-th v-if="$route.query.tab === 'origin_freight_fees'">ຄ່າຂົນສົ່ງຕົ້ນທາງ</vs-th>
            <vs-th v-else-if="$route.query.tab === 'destination_freight_fees'">ຄ່າຂົນສົ່ງປາຍທາງ</vs-th>
            <vs-th v-else-if="$route.query.tab === 'origin_freight_fees_cod'">ຄ່າຂົນສົ່ງຕົ້ນທາງ</vs-th>
            <vs-th v-else>ຄ່າຂົນສົ່ງ</vs-th>
            <vs-th>ປະກັນໄພ</vs-th>
            <vs-th>ລວມຄ່າຂົນສົ່ງ</vs-th>
            <vs-th>ຄ່າຕີກັບ</vs-th>
            <vs-th>ຄ່າຝາກສາງ</vs-th>
            <vs-th>ຄ່າສິນຄ້າ</vs-th>
            <vs-th>ຊື່ຜູ້ສົ່ງ</vs-th>
            <vs-th>ຊື່ຜູ້ຮັບ</vs-th>
            <vs-th>ສາຂາຕົ້ນທາງ</vs-th>
            <vs-th>ສາຂາປາຍທາງ</vs-th>
            <vs-th>ປະເພດການຊຳລະ</vs-th>
            <vs-th>ປະເພດຂົນສົ່ງ</vs-th>
            <vs-th>ຜູ້ຮັບຜິດຊອບ</vs-th>
            <vs-th>ຜູ້ຈັດສົ່ງ</vs-th>
            <vs-th>ຮູບລາຍເຊັນ</vs-th>
            <vs-th>ໝາຍເຫດ</vs-th>
          </vs-tr>
        </template>
        <template #tbody>
          <vs-tr
              :key="index"
              :data="item"
              v-for="(item, index) in listAllOrders"
          >
            <!--            :is-selected="isRowSelected(item.id)"-->
            <!--            @selected="onItemCheckBoxChanged(item)"-->
            <!--            <vs-td>-->
            <!--              <div class="center con-checkbox">-->
            <!--                <vs-checkbox danger-->
            <!--                             :checked-force="isRowSelected(item.id)"-->
            <!--                             :val="item" @click="onItemCheckBoxChanged(item)">-->
            <!--                  <span :style="{fontSize: '16px', marginLeft: '10px'}">{{ index + 1 }}</span>-->
            <!--                </vs-checkbox>-->
            <!--              </div>-->
            <!--            </vs-td>-->
            <vs-td style="text-align: right; width: 100px">
              <div class="group-icon">
                <vs-button circle icon flat
                           @click="$router.push({name: 'list.shipment.detail', params:{id: item.id}})">
                  <i class="fad fa-eye"></i>
                </vs-button>
              </div>
            </vs-td>
            <vs-td :style="{color: 'crimson'}">( {{ item.shipment_number }} )</vs-td>
            <vs-td v-if="item.shipment_status_id === 1">{{ FormatDate(item.created_at) }}</vs-td>
            <vs-td v-else>{{ FormatDate(item.start_date_actual) }}</vs-td>
            <vs-td v-if="item.shipment_status_id !== 1
            && item.shipment_status_id !== 2
            && item.shipment_status_id !== 3
            && item.shipment_status_id !== 11">{{ FormatDate(item.updated_at) }}
            </vs-td>
            <vs-td v-else></vs-td>

            <vs-td v-if="item.tracking_state_id === 5 || item.tracking_state_id === 6">
              {{ FormatDate(item.end_date_actual) }}
            </vs-td>
            <vs-td v-else></vs-td>

            <vs-td v-if="item.shipment_status_id === 11">
              {{ FormatDate(item.end_date_actual) }}
            </vs-td>
            <vs-td v-else-if="item.shipment_status_id === 3">
              {{ FormatDate(item.updated_at) }}
            </vs-td>
            <vs-td v-else></vs-td>
            <vs-td
                v-if="item.tracking_state_id === 5 && (item.shipment_status_id === 2 || item.shipment_status_id === 6 || item.shipment_status_id === 8)">
              ເຄື່ອງຮອດສາຂາປາຍທາງ
            </vs-td>
            <vs-td v-else>{{ la_status[nullsafe(item, t => t.shipment_status.name)] }}</vs-td>
            <vs-td v-if="item.tracking">{{ item.tracking }}</vs-td>
            <vs-td v-else></vs-td>
            <vs-td>{{ item.pieces || 0 }}</vs-td>
            <vs-td>{{ nullsafe(item, t => t.parcels_summary.total_weight) || 0 }}</vs-td>
            <vs-td>
              <span v-if="item.total_discount_amount !== 0">{{ item.total_discount_amount }}ກີບ</span>
              <span v-if="item.total_discount_percent !== 0">{{ item.total_discount_percent }}%</span>
              <span v-if="item.total_discount_amount === 0 && item.total_discount_percent === 0">........</span>
            </vs-td>
            <vs-td>( {{ FormatPrice(item.sub_total_freight) }} )</vs-td>
            <vs-td>( {{ FormatPrice(nullsafe(item, t => t.parcel.insurance_price) || 0) }} )</vs-td>
            <vs-td :style="{color: 'crimson'}">( {{ FormatPrice(item.total_freight) }} )</vs-td>
            <vs-td :style="{color: 'crimson'}">( {{ FormatPrice(item.reject_freight) }} )</vs-td>
            <vs-td :style="{color: 'crimson'}">( {{ FormatPrice(item.total_rental_price) }} )</vs-td>
            <vs-td :style="{color: 'crimson'}">( {{ FormatPrice(item.total_price) }} )</vs-td>
            <vs-td>{{ nullsafe(item, t => t.sender_customer.name) }}</vs-td>
            <vs-td>{{ nullsafe(item, t => t.receiver_customer.name) }}</vs-td>
            <vs-td>{{ nullsafe(item, t => t.start_branch.province.prefix) }}-{{
                nullsafe(item, t => t.start_branch.name)
              }}
            </vs-td>
            <vs-td>{{ nullsafe(item, t => t.end_branch.province.prefix) }}-{{
                nullsafe(item, t => t.end_branch.name)
              }}
            </vs-td>
            <vs-td>
              <span v-if="item.shipment_pay_type === 'origin_freight_fees'">ເກັບເງິນຕົ້ນທາງ</span>
              <span v-if="item.shipment_pay_type === 'destination_freight_fees'">ເກັບເງິນປາຍທາງ</span>
              <span v-if="item.shipment_pay_type === 'origin_freight_fees_cod'">ເກັບເງິນຕົ້ນທາງ ( COD )</span>
              <span v-if="item.shipment_pay_type === 'destination_freight_fees_cod'">ເກັບເງິນປາຍທາງ ( COD + CDC )</span>
            </vs-td>
            <vs-td :style="{color: 'crimson'}">
              ( {{ item.shipment_type }} )
            </vs-td>
            <vs-td>{{ nullsafe(item, t => t.owner.name) }}</vs-td>

            <vs-td v-if="item.shipment_status_id === 3">{{ nullsafe(item, t => t.sign_user.name) }}</vs-td>
            <vs-td v-else></vs-td>


            <vs-td>
              <div v-if="item.signature_file" class="img-size">
                <expandable-image
                    class="image"
                    :src=item.signature_file
                ></expandable-image>
              </div>
            </vs-td>
            <vs-td>{{ item.remark }}</vs-td>
          </vs-tr>
        </template>
        <template #footer>
          <pagination :pagination="pagination" :offset="offset"
                      @paginate="FetchData()"></pagination>
          <Loading v-if="isLoading"/>
        </template>
      </vs-table>
    </div>
  </div>
</template>

<script>

import Loading from '../../Loading';
import MixinBranchListOrders from '../../../Mixins/Shipments/New-List-Shipment';
import DatePicker from "vue2-datepicker";
import {ModelListSelect} from 'vue-search-select';
import pagination from '../../../Plugins/Paginations/CusorPagination';

export default {
  components: {
    Loading,
    DatePicker,
    ModelListSelect,
    pagination
  },

  props: ['onclickEvent'],

  mixins: [MixinBranchListOrders],

  methods: {
    nullsafe(instance, func) {
      try {
        return func(instance);
      } catch (_) {
        return undefined
      }
    },
    // FilterUsers() {
    //   const userId = this.userCreateBy.map(item => item.id);
    //   const groupByUserId = _.uniq(userId);
    //
    //   this.$axios.get('shipment/user', {
    //     params: {
    //       user: groupByUserId
    //     }
    //   }).then((res) => {
    //     this.users = res.data;
    //     this.users.unshift({
    //       id: '',
    //       name: 'ທັງຫມົດ'
    //     });
    //   }).catch(() => {
    //   });
    // },
    //
    // FilterSignUsers() {
    //   const userId = this.signUserCreateBy.map(item => item.id);
    //   const groupByUserId = _.uniq(userId);
    //
    //   this.$axios.get('shipment/user', {
    //     params: {
    //       user: groupByUserId
    //     }
    //   }).then((res) => {
    //     this.signUsers = res.data;
    //     this.signUsers.unshift({
    //       id: '',
    //       name: 'ທັງຫມົດ'
    //     });
    //   }).catch(() => {
    //   });
    // }
  },
  created() {
    this.FetchListStartBranch();
    this.FetchListEndBranch();
    this.FetchShipmentStatus();
    // this.shipmentFilters();
    this.FetchData();
  },

  watch: {
    'onclickEvent': function (value) {
      if (value !== '') {
        this.ClearData();
        this.FetchData();
      }
    }
  }
}
</script>

<style lang="scss">
.btn-clear {
  background: $crimson;

  &:hover {
    color: $white;
  }
}

.vs-select__input {
  width: 55% !important;
  margin-left: -163px !important;
  border-radius: 5px !important;
  background: $white !important;
  border: 1px solid rgba(173, 172, 172, 0.562) !important;
}

.table-limit-height {
  .vs-table {
    width: 100%;
    font-size: 0.9rem;
    margin: 0;
    max-height: 500px;
  }
}

</style>
