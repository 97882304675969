import { render, staticRenderFns } from "./viewFeedBackDetail.vue?vue&type=template&id=23d5fdef&scoped=true&"
import script from "./viewFeedBackDetail.vue?vue&type=script&lang=js&"
export * from "./viewFeedBackDetail.vue?vue&type=script&lang=js&"
import style0 from "./viewFeedBackDetail.vue?vue&type=style&index=0&id=23d5fdef&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23d5fdef",
  null
  
)

export default component.exports