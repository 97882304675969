import { render, staticRenderFns } from "./SuccessRegister.vue?vue&type=template&id=4c8e0426&scoped=true&"
import script from "./SuccessRegister.vue?vue&type=script&lang=js&"
export * from "./SuccessRegister.vue?vue&type=script&lang=js&"
import style0 from "./SuccessRegister.vue?vue&type=style&index=0&id=4c8e0426&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c8e0426",
  null
  
)

export default component.exports