import { render, staticRenderFns } from "./randomBag.vue?vue&type=template&id=419cbbbb&scoped=true&"
import script from "./randomBag.vue?vue&type=script&lang=js&"
export * from "./randomBag.vue?vue&type=script&lang=js&"
import style0 from "./randomBag.vue?vue&type=style&index=0&id=419cbbbb&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "419cbbbb",
  null
  
)

export default component.exports