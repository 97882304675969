import { Validator } from 'vee-validate';

const validate_DistributionCenter = {
    custom: {
        name:{
            required: '( ກະລຸນາປ້ອນກ່ອນ... )',
        },
        tel:{
            required: '( ກະລຸນາປ້ອນກ່ອນ... )',
            min: '( ເບີໂທບໍ່ຄວນໜ້ອຍກວ່າ 6 ໂຕເລກ... )',
            max: '( ເບີໂທບໍ່ຄວນເກີນ 15 ໂຕເລກ... )',
        },
        email:{
            email: '( ອີເມວບໍ່ຖືກຕ້ອງ... )',
            max: '( ອີເມວບໍ່ຄວນຍາວກ່ວາ 255 ໂຕ... )'
        },
        part:{
            required: '( ກະລຸນາເລືອກກ່ອນ... )',
        },
        province:{
            required: '( ກະລຸນາເລືອກກ່ອນ... )',
        },
        district:{
            required: '( ກະລຸນາເລືອກກ່ອນ... )',
        },
        village:{
            required: '( ກະລຸນາເລືອກກ່ອນ... )',
        },
        prefix:{
            required: '( ກະລຸນາປ້ອນກ່ອນ... )',
        },
        distributioncenterId:{
            required: '( ກະລຸນາເລືອກສູນຄັດເເຍກກ່ອນ... )'
        },
        distribution_center_strict_type:{
            required: '( ກະລຸນາເລືອກປະເພດການເຊື່ອມຕໍ່... )'
        }
    }
};

export const ValidateDistributionCenter = (Vue) => {
    Validator.localize('en', validate_DistributionCenter);
    Vue.$validator.localize('en', validate_DistributionCenter);
}
